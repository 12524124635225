import { Disclosure } from '@headlessui/react'
import Layout from '@@/Layout'
import tw from 'tailwind-styled-components'
import AccountDropdown from './components/AccountDropdown'
import Link from '@@/Link'
import { useUser } from '@coding4tomorrow/c4t-next-core'

const StyledDisclosure = tw(Disclosure)<any>`
  bg-white
  border-b
  border-gray-200
`

const LiteLayout = ({ children }: any) => {
  const { user } = useUser()
  return (
    <Layout>
      <div className="min-h-screen bg-global-background bg-cover bg-no-repeat flex flex-col">
        <StyledDisclosure as="nav">
          <div className="sticky h-[8vh] flex items-center justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <span className="text-3xl font-bold leading-tight text-indigo-600 cursor-pointer">
                    <Link
                      href={user ? '/' : '/login'}
                      className="text-text-color hover:text-text-color"
                    >
                      GLEAM
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <AccountDropdown />
          </div>
        </StyledDisclosure>
        <main className="min-h-full w-full grow flex flex-col">
          <div className="grow w-full h-full flex justify-center">
            {children}
          </div>
        </main>
      </div>
    </Layout>
  )
}
export default LiteLayout
