import { useState } from 'react'
import CreateEventModal from '../CreateEventModal'
import InformationTemplate from '@/InformationTemplate'

const CreateFirstEvent = ({ mutateEvents }: { mutateEvents: () => void }) => {
  const [isModalOpen, isModalOpenSet] = useState(false)

  return (
    <>
      <InformationTemplate
        imageLink="/illustrations/visual-create-event-page.svg"
        description="Creating an event (in less than 5 minutes) will allow you to launch a
         session. Invite your selected speakers and broadcast your session to
        your audience."
        title="The best interface is your face.Start creating a session, now."
        buttonContent="Create your first event"
        onButtonClick={() => isModalOpenSet(true)}
      />
      <CreateEventModal
        isOpen={isModalOpen}
        setIsOpen={isModalOpenSet}
        mutateEvents={mutateEvents}
      />
    </>
  )
}

export default CreateFirstEvent
